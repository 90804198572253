<script>
	import { meta } from 'tinro'

    let request = getData()

	async function getData() {
		const res = await fetch('https://nefub.nl/API/competition.php?id=' + meta().params.id)
		const json = await res.json()
		if (!res.ok) {
			throw new Error()
		}
		return json
	}
</script>

{#await request then json}
	<h2>{json.Name}</h2>
	{#if json.Poules}
		<table>
			{#each json.Poules as Poule}
			<tr>
				<td><a href="/poule/{Poule.ID}">{Poule.Name}</a></td>
			</tr>
			{/each}
		</table>
	{:else}
		<p>Er zijn nog geen poules.</p>
	{/if}
{:catch}
    <p class="error">Het ophalen van data is mislukt.</p>
{/await}
