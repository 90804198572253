<script>
    import { meta } from "tinro";

    let request = getData()

    async function getData() {
        const res = await fetch('https://nefub.nl/API/team.php?id=' + meta().params.id)
        const json = await res.json()
        if (!res.ok) {
            throw new Error()
        }
        return json
    }
</script>


{#await request then json}
    <h2>{json.Name}</h2>
    <p>{json.Competition.Name} | <a href="/club/{json.Club.ID}">{json.Club.Name}</a></p>
    <table>
        <tr>
            <th>Nr.</th>
            <th>Naam</th>
            <th>Wedstrijden</th>
            <th>Assists</th>
            <th>Goals</th>
        </tr>
        {#each json.Players as Player}
        <tr>
            <td>{Player.Number}</td>
            <td>{Player.Player.Name}</td>
            <td>{Player.Statistics['Number of Games']}</td>
            <td>{Player.Statistics.Assists}</td>
            <td>{Player.Statistics.Goals}</td>
        </tr>
        {/each}
    </table>
{:catch}
    <p class="error">Het ophalen van data is mislukt.</p>
{/await}
