<script>
    import { meta } from 'tinro'

	let request = getData()

	async function getData() {
		const res = await fetch('https://nefub.nl/API/schedule.php?day=' + meta().params.day)
		const json = await res.json()
		if (!res.ok) {
			throw new Error()
		}
		return json
	}
</script>

{#await request then json}
	<h2>Wedstrijden {meta().params.day}</h2>
	<table>
	{#each json.Games as Game}
		<tr>
			<td>{Game.Time}</td>
			<td><a href="/team/{Game.Home.ID}">{Game.Home.Name}</a> - <a href="/team/{Game.Away.ID}">{Game.Away.Name}</a></td>
			<td>{Game.Location.Name}, {Game.Location.Place}</td>
		</tr>
	{/each}
	</table>
{:catch}
    <p class="error">Het ophalen van data is mislukt.</p>
{/await}