<script>
    import { meta } from "tinro";

    let request = getData()

    async function getData() {

        const results = await Promise.all([
            fetch('https://nefub.nl/API/club.php?id=' + meta().params.id),
            fetch('https://nefub.nl/API/teams.php?club=' + meta().params.id)
        ])

        const json = {}
        json.Club = await results[0].json()
        json.Teams = await results[1].json()

        if (!results[0].ok || !results[1].ok) {
            throw new Error()
        }

        return json
    }
</script>

{#await request then json}
    <h2>{json.Club.Name}</h2>
    {#if json.Club.Website}
        <p><a href="{json.Club.Website}" target="_blank">{json.Club.Website}</a></p>
    {/if}
    <table>
        <tr>
            <th>Team</th>
            <th>Competitie</th>
        </tr>
        {#each json.Teams.Teams as Team}
            <tr>
                <td><a href="/team/{Team.ID}">{Team.Name}</a></td>
                <td>{Team.Competition.Name}</td>
            </tr>
        {/each}
    </table>
{:catch}
    <p class="error">Het ophalen van data is mislukt.</p>
{/await}