<script>
	import { meta } from 'tinro'

    let request = getData()

	async function getData() {
		const res = await fetch('https://nefub.nl/API/poule.php?id=' + meta().params.id)
		const json = await res.json()
		if (!res.ok) {
			throw new Error()
		}
		return json
	}
</script>

{#await request then json}
	<h2>{json.Name}</h2>
	<table>
		<tr>
			<th>Pos.</th>
			<th>Team</th>
			<th>Punten</th>
			<th colspan="2">Wedstrijden</th>
			<th colspan="2">Doelsaldo</th>
		</tr>
		{#each json.Ranking as Rank}
		<tr>
			<td>{Rank.Position}</td>
			<td><a href="/team/{Rank.Team.ID}">{Rank.Team.Name}</a></td>
			<td>{Rank.Points}</td>
			<td>{Rank['Number of Games'].Played}</td>
			<td>({Rank['Number of Games'].Won}/{Rank['Number of Games'].Draw}/{Rank['Number of Games'].Lost})</td>
			<td>{Rank['Number of Goals'].Difference}</td>
			<td>({Rank['Number of Goals'].Scored}-{Rank['Number of Goals'].Conceded})</td>
		</tr>
		{/each}
	</table>
	{#if json.Information}
		<p>{json.Information}</p>
	{/if}
{:catch}
    <p class="error">Het ophalen van data is mislukt.</p>
{/await}
