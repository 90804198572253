<script>
    let request = getData()

    async function getData() {
        const res = await fetch('https://nefub.nl/API/season.php?id=2021')
        const json = await res.json()
        if (!res.ok) {
            throw new Error()
        }
        return json
    }
</script>


{#await request then json}
    <h2>Competities {json.Name}</h2>
    <table>
        <tr>
            <th>Naam</th>
            <th>Type</th>
        </tr>
        {#each json.Competitions as Competition}
            <tr>
                <td><a href="/competitie/{Competition.ID}">{Competition.Name}</a></td>
                <td>{Competition.Type}</td>
            </tr>
        {/each}
    </table>
{:catch}
    <p class="error">Het ophalen van data is mislukt.</p>
{/await}
