<script>
    import { Route } from 'tinro'
	import Speeldagen from './Speeldagen.svelte'
	import Speeldag from './Speeldag.svelte'
	import Clubs from './Clubs.svelte'
	import Club from './Club.svelte'
	import Competities from './Competities.svelte'
	import Competitie from './Competitie.svelte'
	import Team from './Team.svelte'
	import Poule from './Poule.svelte'
</script>

<nav>
    <a href="/">Speeldagen</a>
    <a href="/clubs">Clubs</a>
    <a href="/competities">Competities</a>
</nav>
<main>
	<Route path="/"><Speeldagen/></Route>
	<Route path="/speeldag/:day"><Speeldag/></Route>
    <Route path="/clubs"><Clubs/></Route>
    <Route path="/club/:id"><Club/></Route>
	<Route path="/competities"><Competities/></Route>
    <Route path="/competitie/:id"><Competitie/></Route>
	<Route path="/team/:id"><Team/></Route>
	<Route path="/poule/:id"><Poule/></Route>
</main>

<style>
    nav {
        display: flex;
        background: black;
    }
    nav a {
        padding: 10px;
        color: white;
    }
    main {
        max-width: 1024px;
        margin: 0 auto 30px auto;
        padding: 8px;
    }
</style>