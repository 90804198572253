<script>
    let request = getData()

    async function getData() {
        const res = await fetch('https://nefub.nl/API/clubs.php')
        const json = await res.json()
        if (!res.ok) {
            throw new Error()
        }
        return json
    }
</script>

{#await request then json}
    <h2>Clubs</h2>
    <table>
        {#each json.Clubs as Club}
            <tr>
                <td><a href="/club/{Club.ID}">{Club.Name}</a></td>
                <td>{Club.Place || '-'}</td>
            </tr>
        {/each}
    </table>
{:catch}
    <p class="error">Het ophalen van data is mislukt.</p>
{/await}
