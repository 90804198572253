<script>
    let request = getData()

    async function getData() {
        const res = await fetch('https://nefub.nl/API/playing_days.php?season=2021')
		const json = await res.json()
        if (!res.ok) {
            throw new Error()
        }
        return json
    }

    // Create a comma seperated list
    function list(items, key) {
        let list = []
        for (const item of items) {
            if (!list.includes(item[key])) {
                list.push(item[key])
            }
        }
        return list.join(', ')
    }
</script>

{#await request then json}
    <h2>Speeldagen</h2>
    <table>
        {#each json['Playing Days'] as Day}
            <tr>
                <td><a href="/speeldag/{Day.ID}"><strong>{Day.ID}</strong></a></td>
                <td>
                    {list(Day.Locations, 'Place')}<br>
                    {list(Day.Competitions, 'Name')}
                </td>
            </tr>
        {/each}
    </table>
{:catch}
    <p class="error">Het ophalen van data is mislukt.</p>
{/await}
